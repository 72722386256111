<template>
  <v-btn
    @click="buttonClicked"
    class="white--text button-width"
    depressed
    rounded
    :color="color ? color : 'primary'"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "PrimaryButton",
  methods: {
    buttonClicked() {
      this.$emit("click");
    }
  },
  props: {
    color: String
  }
};
</script>

<style scoped>
.button-width {
  width: 100%;
}
</style>
